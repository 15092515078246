* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: rgba(33, 149, 243, 0.1);
  font-family: "Roboto";
  font-weight: 300;
}

body>.container {
  width: 100vw;
  height: 100vh;
  display: flex;
}

.page {
  display: none;
}

.page-active {
  display: block;
}

.container {
  display: flex;
}

.item {
  cursor: pointer;
  width: 50%;
  flex-grow: 1;
  overflow: hidden;
}

.facebook {
  background-color: #deebf5;
  border-right: 4px solid #fff;
}

.google {
  background-color: #fff;
}

.facebook svg {
  width: 184%;
  height: 100vh;
  margin-left: -42%;
  fill: #3f8dc7;
}

.google svg {
  width: 190%;
  height: 100vh;
  margin-left: -30%;
}

#toast-container {
  min-width: 10%;
  bottom: 3%;
  right: 50%;
  transform: translateX(50%) translateY(50%);
  -webkit-transform: translateX(50%) translateY(50%);
  -moz-transform: translateX(50%) translateY(50%);
  -ms-transform: translateX(50%) translateY(50%);
  -o-transform: translateX(50%) translateY(50%);
}

.header-navbar #nav-mobile {
  background-color: #00befa;
}

#dropdown1 {
  margin-top: 35px;
}

.top-sidenav {
  padding: 5px;
}

.user-view img {
  margin: 0 auto;
  margin-top: -15px;
  margin-bottom: 35px;
}

.userPhoto {
  width: 40px;
  margin-top: 4px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

#cardUserPhoto {
  width: 40px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin: 0 auto;
}

.button-add {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 13px;
  margin-bottom: 60px;
}

.card {
  width: 95%;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.bet-card-content {
  display: grid;
  grid-template-columns: 40px 1fr 1fr;
}

.profile-pic {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.profile-name {
  margin-left: 20px;
}

.item-date {
  justify-self: end;
  text-align: right;
}

.item-details {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 0 15px 15px;
}

.card-title {
  font-size: 1.3em;
}

.card-result {
  font-size: 1.3em;
}

#bet-remove-text {
  text-align: center;
  font-weight: 400;
  padding: 20px;
}

#bet-remove-actions {
  text-align: center;
}

.card-actions {
  text-align: center;
}

.card-actions i {
  padding: 0 20px;
}

#stats .card-content {
  margin: 0 auto;
  padding: 30px;
  text-align: center;
}

#stats .card {
  color: #fff;
  background-color: #00befa;
}

#add-bet-card {
  width: 90%;
  padding: 0 15px;
  border-radius: 4px;
  position: absolute;
  top: 25vh;
  left: 5vw;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.overlay {
  filter: blur(4px);
  -webkit-filter: blur(4px);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0.8;
}

.flip-vertical-fwd {
  -webkit-animation: flip-vertical-fwd 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: flip-vertical-fwd 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  -webkit-animation: flip-vertical-fwd 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  -webkit-backface-visibility: ;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-3-5 9:32:11
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes flip-vertical-fwd {
  0% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
  }

  100% {
    -webkit-transform: translateZ(160px) rotateY(180deg);
    transform: translateZ(160px) rotateY(180deg);
  }
}

@keyframes flip-vertical-fwd {
  0% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
  }

  100% {
    -webkit-transform: translateZ(160px) rotateY(180deg);
    transform: translateZ(160px) rotateY(180deg);
  }
}

.card .card-content {
  padding: 15px;
}

/* .circle {
  width: 10px;
  height: 10px;
  background-color: red;
  position: absolute;
  left: 92%;
} */

.card .card-action {
  padding: 5px;
  text-align: right;
}

.card-action-icon {
  cursor: pointer;
  color: #00befa;
}

.tabs .tab a:focus.active {
  background-color: transparent;
}

.tabs .tab a:hover {
  color: #fff;
}

.tabs .tab a {
  color: rgb(255, 255, 255) !important;
}

.tabs .indicator {
  background-color: #fff;
}

.footer-navbar {
  margin-top: 120px;
  position: fixed;
  bottom: 0%;
  height: 50px;
}

.footer-navbar #nav-mobile a {
  color: rgb(105, 105, 105);
}

@media (min-width: 700px) {
  body>.container {
    width: 60%;
  }
}
